import styled from "styled-components";


export const HeaderWidget = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  p, a {
    font-size: 24px;
    cursor: pointer;
    color: #111;
    text-decoration: none;
  }
`
