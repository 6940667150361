import React from "react";
import {AutoWidget} from "../../widget/home_widget/auto";
import {VodieWidget} from "../../widget/home_widget/vodie";
import styled from "styled-components";
import {WidthStyle} from "../../widget/base/width_style";
import {ProtfolioPage} from "../protfolio/protfolio";
import {Element} from 'react-scroll';

export const HomePage: React.FC = () => {
    return (
        <>
            <Element name="test1" className="element"/>
            {/*<AutoWidget/>*/}
            <VodieWidget />
            <Element name="test2" className="element"/>
            <CardWidget>
                <div className="title">
                    <h4 className={'sub'}>investment institutions</h4>
                    <h5 className={'context'}>Portfolio</h5>
                    <h4 className={'sub'}>Random arrangement </h4>
                </div>

                <ProtfolioPage/>
            </CardWidget>
        </>

    );
}


const CardWidget = styled.div`
  font-weight: 400;
  line-height: 1.6;
  padding: 2% 4%;
  box-sizing: border-box;
  min-height: 40vh;
  display: flex;
  ${() => WidthStyle.MaxWidth};
  flex-flow: column;

  .title {
    margin: 20px 0;
    text-align: center;
    padding-bottom: 20px;

    .sub {
      height: 17px;
      font-size: 12px;
      font-weight: 600;
      color: #0F1111;
    }

    .context {
      font-size: 66px;
      color: #0F1111;
      line-height: 76px;
      font-weight: bold;
      text-transform: uppercase;

    }
  }

  @media (max-width: 768px) {
    width: 94%;
    margin:  0;
    .title{
      padding-bottom: 0px;
      .context{
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
`;
