import React from "react";
import {CardWidget, HeaderWidget,FooterWidget} from "../../widget/base";

import {ImagesListWidget} from "../../widget/protfolio_widget/images_list";

export const ProtfolioPage:React.FC = () =>{
    return (
        <CardWidget>
            <ImagesListWidget />
        </CardWidget>
    );
}
