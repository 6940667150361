import styled from "styled-components";
import React from "react";
import {Grid} from "@material-ui/core";
import radicleImages from "../../assets/images/Radiccle_logo_3@2x.png"
import redImages from "../../assets/images/Ren_logo_4@2x.png"
import nsureImages from "../../assets/images/Nsure_logo_5@2x.png"
import hoprImages from "../../assets/images/hopr_logo_6@2x.png"
import polkaImages from "../../assets/images/polkadot_logo_7@2x.png"
import greenImages from "../../assets/images/green-color.png"
import numioLogo from "../../assets/images/numio_logo.png"
import numioLogo1 from "../../assets/images/numio_logo@2x.png"
import numioLogo2 from "../../assets/images/nuio_logo@2x.png"
import fxLogo from "../../assets/images/fx.png"
import transparentLogo from "../../assets/images/nftcompanyTransparent.png"
import UnfiLogo from "../../assets/images/Unfi_logo@2x.png"
import MysoIcon from "../../assets/images/Myso_icon@2x.png"
import AmuletIcon from "../../assets/images/Amulet_icon@2x.png"
import OlaGuildGame from "../../assets/images/Ola_Guild_Game _logo@2x.png"


type cellData = { img: string, url: string, text: string, color: string, name?: string }
const ListData: Array<cellData> = [
    {
        img: UnfiLogo,
        color: "#EFF9FB",
        url: "https://unifiprotocol.com/",
        name: "Unfi",
        text: `Unifi Protocol creates innovative and straightforward solutions for Web3 applications.`
    },
    {
        img: MysoIcon,
        color: "#EFF9FB",
        url: "https://www.myso.finance/coming-soon",
        name: "Myso",
        text: `Myso builds a liquidation-free, fixed-interest and oracle-free borrowing solution. Borrowers are freed from liquidation risk while liquidity providers gain exposure to a passive option writing strategy, unlocking option selling as a sustainable yield source.`
    },
    {
        img: AmuletIcon,
        color: "#EFF9FB",
        url: "https://amulet.org/",
        name: "Amulet",
        text: `Amulet is a Defi insurance protocol for the Rust-based ecosystem with initial deployment on Solana. It is creating the industry’s first Protocol-Controlled Underwriting (PCU) approach, in which Amulet will build up its own underwriting capabilities and introduce a claim structure involvingmultiple buffer layers.`
    },
    {
        img: OlaGuildGame,
        color: "#EFF9FB",
        url: "https://radicle.xyz/",
        name: "Ola Guild Game",
        text: `Building the largest and most engaged Hispanic PlayToEarn community by leveraging best in class Web3 products and Education. and a global presence as a part of YGG`
    },

    {
        img: radicleImages,
        color: "#EFF9FB",
        url: "https://radicle.xyz/",
        name: "Radicle",
        text: `Radicle is a decentralized code collaboration network built on open protocols 🌱 . It enables developers to collaborate on code without relying on trusted intermediaries.`
    },
    {
        img: redImages,
        color: "#E4E4F2",
        url: "https://renproject.io/",
        name: "Ren",
        text: `RenVM, a permissionless and decentralized virtual machine protocol
A secure network of virtual computers that power interoperability for decentralized applications, enabling cross-chain lending, exchanges, collateralization & more.`
    },
    {
        img: greenImages,
        color: "#EEF6F0",
        url: "https://numio.one/faq/",
        name: "Numio",
        text: `Numio is a mobile application built on Ethereum, which is the first product supporting zkRollups (an Ethereum scaling solution) on Google Play. Numio gives people the the power to have more control over their money and digital identity.`
    },
    {
        img: hoprImages,
        color: "#F4F4F4",
        name: "Hopr",
        url: "https://hoprnet.io",
        text: `HOPR is pioneering decentralized, community-enabling governance (DecenGov) as a techno-legal framework that combines the dynamics of communities with the efficiencies of crypto networks and the advantages of established legal bodies to govern collective efforts.`
    },
    {
        img: polkaImages,
        color: "#E7EAEC",
        name: "Polkadot",
        url: "https://polkadot.network/",
        text: "A scalable, interoperable & secure network protocol for the next web."
    },
    {
        img: nsureImages,
        color: "#E8EAFC",
        name: "Nsure",
        url: "https://nsure.network",
        text: `Nsure is an open insurance platform for Open Finance. The project borrows the idea of Lloyd's London, a market place to trade insurance risks, where premiums are determined by a Dynamic Pricing Model. Capital mining will be implemented to secure capital required to back the risks at any point of time.`
    },
    {
        img: numioLogo,
        color: "#F1F6FF",
        url: "http://titan.io/",
        name: "titan",
        text: `The Titan network introduces a new protocol for provable hashpower. The result is a new blockchain platform for buying, selling, and delivering hashpower globally, creating a tradeable commodity while decentralizing the control of hashing power over time.`
    },
    {
        img: numioLogo1,
        color: "#F8646442",
        name: "Anima",
        url: "https://anima.supply/",
        text: `Anima, a small creative crypto startup built by the founders of photo/video app Ultravisual, which Flipboard acquired back in 2014, is looking to use AR to shift how NFT art and collectibles can be viewed and shared. Their latest venture is an effort to help artists bring their digital creations to a bigger digital stage and help find what the future of NFTs looks like in augmented reality.`
    },
    {
        img: numioLogo2,
        color: "#7CAFC4",
        name: "Defilend",
        url: "https://defiland.app/",
        text: `DeFi Land is a multi-chain agriculture-simulation web-game created to gamify decentralized finance.                                        DeFi Land will be running on top of established platforms, meaning users will be using well-known AMMs/DEXs/lending protocols through our game.`
    },
    {
        img: transparentLogo,
        color: "#BEE1E1",
        url: "https://opgames.org/",
        name: "Opgame",
        text: `OP Games allows developers to turn entire games into fractionalized NFTs. This means players can own a fraction of the game and co-own the project. Through this co-ownership, players can enjoy price appreciation if the game succeeds, and even shape the game by participating in its DAO. For developers, this model means a revolution in terms of project financing and monetization.`
    },
]

export const ImagesListWidget: React.FC = () => {
    return (
        <Grid container spacing={2} justify={"flex-start"}>
            {
                ListData.map((ev) => {
                    return <CellWidget modal={ev}/>;
                })
            }
        </Grid>
    );
}

const CellWidget: React.FC<{ modal: cellData }> = ({modal}) => {
    return (
        <WapHover item sm={4} md={3} xs={12}>
            <ImagesListStyle bgColor={modal.color} href={modal.url} target="_blank" key={modal.url}>
                <div className="font">
                    <div className="top">
                        <img src={modal.img} alt={modal.img}/>
                    </div>
                    <div className="msg">{modal.name}</div>
                </div>
                <div className="back">

                    <div className="msg">{modal.text}</div>
                </div>


            </ImagesListStyle>
        </WapHover>
    );
}

const WapHover = styled(Grid)`

@media (min-width: 768px) {
    &:hover > a {
        transform: rotateY(180deg);
        background: linear-gradient(180deg, #52C234 0%, #061700 100%);
    
      }

}

  
`

const ImagesListStyle = styled.a<{ bgColor: string }>`
  background: #FFF8F2;

  display: block;
  text-decoration: none;
  //padding: 66px 0;
  box-sizing: border-box;
  box-shadow: 0px 0px 19px 0px rgba(75, 81, 80, 0.08);
  //transform-style: preserve-3d;
  //transform-origin: 50px 50px;
  transition: all 1s;
  transform-style: preserve-3d;
  width:100%;
  min-height: 300px;
  position: relative;
  > div {
  }


  .font, .back {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    
    position: absolute;
    //top:0;
    //left:0;
    width:100%;
    height:100%;
    text-align: center;
    line-height: 200px;

    backface-visibility: hidden;
  }
  
  .back{
    transform: rotateY(180deg);
    background: linear-gradient(180deg, #52C234 0%, #061700 100%);

    .msg{
      margin-top: 0;
      font-size: 13px;
      font-weight: 400;
      color: #fff;
      padding:0 20px ;
      box-sizing: border-box;


      font-family: OpenSans-Regular, OpenSans;
      text-transform: capitalize;
      text-align: center;
      line-height: 1.2;
    }
  }


  .top {
    display: flex;
    align-items: center;
    img {
      display: block;
      width: 200px;
      max-height: 92px;
      margin: 0 auto;
    }
  }

  .msg {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 600;
    color: #000000;

    font-family: OpenSans-SemiBold, OpenSans;
    text-transform: capitalize;
    text-align: center;
    line-height: 36px;
  }

  .inner {
    padding: 41px;
    box-sizing: border-box;
    font-size: 16px;
    color: #666666;
    line-height: 22px;
  }

  @media (max-width: 768px) {
    .inner {
      padding: 20px;
    }

  }
`
