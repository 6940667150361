import styled from "styled-components";
import React from "react";


export const LinkWidget: React.FC<{top?:number}> = ({top}) => {
    return (
        <LinkStyle top={top} />
    )
}

export const LinkStyle = styled.div<{top?:number}>`
  display: block;
  width: 15%;
  height: 1px;
  background: #dedede;
  margin: ${(props => props.top||50)}px auto 0 auto;
     @media (max-width: 768px) {
  display: none;
  }
`
