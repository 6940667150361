import React, {useCallback, useEffect, useState} from 'react';
import {HomePage} from './page/home/home'
import styled from "styled-components";
import {HashRouter as Router, Switch, Route, Link} from 'react-router-dom'
import {WidthStyle} from "./widget/base/width_style";
import {FooterWidget} from "./widget/base";
import {scroller} from "react-scroll/modules";

function App() {

    const [startTransform, setStart] = useState(false)
    const [opacity, setOpacity] = useState(0)
    const [ac, setAc] = useState(0)

    const handleScroll = useCallback((ev) => {
        let scrollTop = document.documentElement.scrollTop;
        let scrollHeight = document.documentElement.scrollHeight;
        let clientHeight = document.documentElement.clientHeight;

        if (clientHeight + scrollTop > scrollHeight - 50) {
            setStart(true)
        }

        if (scrollTop > (clientHeight * 0.3) && scrollTop < clientHeight) {
            setOpacity((scrollTop / clientHeight))
        }
        setAc(scrollTop > clientHeight - 40 ? 1 : 0);

    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    const goToFn = useCallback((ev: string) => {
        scroller.scrollTo(ev, {
            duration: 500,
            delay: 100,
            smooth: 'easeInOutQuint',
        })
    }, [])

    return (
        <Router>

            <Wap>
                <HeaderWidget opacity={opacity}>
                    <div className="inner">
                        <div>
                            <p onClick={()=>{goToFn("test2")}}>About us <span /></p>
                            <p onClick={()=>{goToFn("test2")}}>Portfolio<span /></p>
                            <a target={'_blank'} href="https://twitter.com/Fir_Capital">Twitter<span /></a>

                        </div>
                    </div>
                </HeaderWidget>
                <HomePage/>
                <FooterWidget text={'Contact Los Caballeros'}/>
            </Wap>
        </Router>

    );
}


export const HeaderWidget = styled.div<{ opacity: number }>`
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  background-color: rgba(31, 38, 36, ${({opacity}) => opacity});
  .inner {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${() => WidthStyle.MaxWidth};

    p, a {
      font-size: 18px;
      cursor: pointer;
      color: #E2F3EF;
      text-decoration: none;
      &:hover{
        span{
          width: 100%;
        }
      }
      span{
        transition: width .3s;
        display: block;
        height: 2px;
        width: 0;
        background: white;
      }
    }

    div {
      display: flex;
      p{
        margin-right: 60px;
        &:last-child{
          margin-right: 0;
        }
      }
      a {
        display: block;
        //margin-left: 30px;
      }
    }

  }

  @media (max-width: 768px) {
    .inner{
      p,a{
        font-size: 12px;
      }

      div{
        p{
          margin-right: 20px;
        }
      }
    }
  }


`


const BottomWidget = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 25px;
  text-align: center;
  padding: 10px 0;
`

const Wap = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
`

export default App;
