import styled, {css} from "styled-components";


export class WidthStyle{

    static MaxWidth = css`
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 900px){
        width: 96%;
      }
    `;


}


