import styled from 'styled-components'
import {WidthStyle} from "./width_style";

export const CardWidget = styled.div`
  min-height: 80vh;
  ${() => WidthStyle.MaxWidth};
  @media (max-width: 768px) {
    width: 94%;
  }
`;
