import {Grid, Hidden} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import topLogo from "../../assets/images/top_logo.png";
import HomeLogo from "../../assets/images/homt_bg.jpg";

const aa = require("../../assets/voide/top_gb.mp4").default;
const phone = require("../../assets/voide/mov_phone.mp4").default;
export const VodieWidget: React.FC = () => {

    const [height, setHeight] = useState(810);
    const [width, setwidth] = useState(0);
    const [postion, setPostion] = useState({left: 0, top: 0})

    let minHeight = 810;
    let minWidth = 1440;
    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        let _height = screen.height
        let _width;
        let _postion = {
            left: 0,
            top: 0,
        };

        _width = _height * 1.7777;
        if (_height < 500) {
            setHeight(minHeight);
            _postion.top = (minHeight - _height) / 2;
            setwidth(minWidth);
        } else {
            setHeight(_height);
            setwidth(_width);
            _postion.left = (_width - document.body.clientWidth) / 2;
        }

        setPostion(_postion);
        // eslint-disable-next-line no-restricted-globals
    }, [screen.height])
    return (
        <VodieStyle>

            <Hidden smUp>
                <div className='inners'>
                    <img src={HomeLogo} alt=""/>
                    {/*<video className={'video'} style={{*/}
                    {/*    height: height + "px",*/}
                    {/*    width: width + 'px',*/}
                    {/*    left: (0 - postion.left) + "px",*/}
                    {/*    top: (0 - postion.top) + "px"*/}
                    {/*}} loop autoPlay muted playsInline>*/}
                    {/*    <source src={phone}/>*/}
                    {/*</video>*/}
                </div>

            </Hidden>
            <Hidden xsDown>
                <div className='inner'>


                    <video className={'video'} style={{
                        height: "100%",
                        width: "100%",
                        // left: (0 - postion.left) + "px",
                        // top: (0 - postion.top) + "px"
                    }} loop autoPlay muted playsInline>
                        <source src={aa}/>
                    </video>
                </div>
            </Hidden>

            {/* <Hidden xsDown>
                <div className='inner'>


                    <video className={'video'} style={{
                        height: "100%",
                        width: "100%",
                        // left: (0 - postion.left) + "px",
                        // top: (0 - postion.top) + "px"
                    }} loop autoPlay muted playsInline>
                        <source src={aa}/>
                    </video>
                </div>

            </Hidden>*/}
            <div className="text">
                <img src={topLogo} alt="" className="logo"/>
                <h4>FIR Capital is a private investment fund focusing on Blockchain Technology. We prefer to help
                    innovators
                    who can lead the change of Times.</h4>
            </div>

        </VodieStyle>
    );
}
const VodieStyle = styled.div`
  background-color: #000;
  position: relative;
  overflow: hidden;
  width: 100%;
  //max-height: 80vh;

  .text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      display: block;
      width: 230px;
      margin-bottom: 40px;
    }

    h4 {
      width: 60%;
      color: #F1F1F1;
      font-weight: bold;
      line-height: 1.6;
      font-size: 32px;
      letter-spacing: .02rem;
      text-align: center;


    }

  }


  .inner {
    height: calc(100vw * 0.5625);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px 3%;
    box-sizing: border-box;
  }


  .inners {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }

  }

  .video {
    position: absolute;
    height: calc(100vw * 0.5625);
  }

  @media (max-width: 600px) {
    //padding: 0 5%;
    box-sizing: border-box;
    .left {
      h3 {
        font-size: 25px;
      }

      h4 {
        font-size: 12px;
        margin-top: 20px;
      }

      a {
        padding: 5px 20px;
        font-size: 16px;
      }
    }

    .text {
      .logo {
        display: block;
        width: 230px;
        margin-bottom: 40px;
      }

      h4 {
        font-size: 16px;
        line-height: 2;
      }
    }

  }
`
