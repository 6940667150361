import styled from "styled-components";
import LogoImg from "../../assets/images/fir_crypto_logo@2x.png"

export const FooterWidget: React.FC<{ text: String }> = ({text}) => {
    return (
        <FooterStyle>
            <div className="left">
                © 2022 Greenfield One and Associates. <br/>All rights reserved.
            </div>
            <div className={'center'}><img src={LogoImg} alt=""/></div>
            <div className="right" onClick={()=>{window.open("https://twitter.com/Fir_Capital")}}>Twitter ↗</div>
        </FooterStyle>
    );
}
const FooterStyle = styled.div`
  background-color: #2a2d2c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
  box-sizing: border-box;
  width: 100vw;

  .right, .left {
    flex: 1;
  }
  
  .right{
    text-align: end;
  }

  img {
    display: block;
    width: 61px;
    height: 24px;
  }

  div {
    color: #E2F3EF;
    display: block;
    padding: 20px 0;
    font-size: 12px;
    text-decoration: none;
    -webkit-transition: all .12s linear 0s;
    -o-transition: all .12s linear 0s;
    transition: all .12s linear 0s;
    font-family: Heebo, sans-serif;
    cursor: pointer;

    &:hover {
      color: #111
    }

    &:active {
      color: #111
    }

    &:focus {
      color: #111
    }
  }
`;
